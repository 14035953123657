import Cookies from "js-cookie";

export default{
    getProfile: state => state.user,
    verifyEmail: state => state.verifyEmail,
    setPanel: state => state.setPanel,
    setPanels: state => state.setPanels,
    userLogged: state => state.userLogged,
    userClub: state => state.userClub,
    isProfileLoaded: state => !!state.user.name,
    isSeller: (state) => {
        
        if(!state.user)
            return false;
            
        let roles =  state.user.roles;

        if(!roles){
            return false;
        }

        var isSeller = false;
        roles.forEach(function(role, index){
            if(role.name === 'Seller'){
                isSeller = true;
               return;
           }
       });
        return isSeller;
    },
    isClient: (state) => {

        let roles =  state.user.roles;

        if(!roles){
            return false;
        }

        var isClient = false;
        roles.forEach(function(role, index){
            if(role.name === 'Client'){
                isClient = true;
               return;
           }
       });
        return isClient;
    },
    getHash: state => state.hash,
    getIndication: state => {
        let indication = state.indication
        if(!indication && Cookies.get('INDICATION') && JSON.parse(Cookies.get('INDICATION')) === 'ENABLED') {
            indication = true
        }
        return indication
    },
    getSubUser: state => state.subUser,
    getPermissionsPanel : state => state.permissionsPanel,
    getSubUserLoading : state => state.subUserLoading,
    getintegrationGreennSales : state => state.integrationGreennSales,

}
