import Cookies from 'js-cookie';
import _refiner from 'refiner-js';
import LastMonthStatistics from '@/services/resources/LastMonthStatistics'

const lastMonthStatistics = LastMonthStatistics.build()

export default {

    userSuccess: (state, resp) => {
        if(resp.settings) {
            if(resp.settings.some(x => x.key === 'MOTHER_NAME')) {
                const mother_name = resp.settings.find(x => x.key === 'MOTHER_NAME').value
                resp.mother_name = mother_name
            }
            if(resp.settings.some(x => x.key === 'IS_POLITICALLY_EXPOSED_PERSON')) {
                const is_politically_exposed_person = resp.settings.find(x => x.key === 'IS_POLITICALLY_EXPOSED_PERSON').value
                resp.is_politically_exposed_person = is_politically_exposed_person
            }
            if(resp.settings.some(x => x.key === 'BIRTH_DATE')) {
                const birth_date = resp.settings.find(x => x.key === 'BIRTH_DATE').value
                resp.birth_date = birth_date
            }
            if(resp.settings.some(x => x.key === 'ENRICHED_DATA')) {
                const enriched_data = resp.settings.find(x => x.key === 'ENRICHED_DATA').value
                resp.enriched_data = enriched_data
            }
        }

        state.user = resp;
        localStorage.user = JSON.stringify(resp)
        Cookies.set('user', JSON.stringify(resp), {
            expires: 365
        })
        
        // Refiner config
        // if(resp.dashboard){
        //     lastMonthStatistics.search()
        //     .then((response) => {
        //         _refiner('setProject', process.env.REFINER_PROJECT_ID)
        //         _refiner('identifyUser', {
        //             id: state.user.id,
        //             email: state.user.email,
        //             name: state.user.name,
        //             subscription_sub100_amount_last30d: response.subscription_sub100_amount_last30d,
        //             sale_plus4000_count_last30d: response.sale_plus4000_count_last30d,
        //             sale_amount_last30d: response.sale_amount_last30d,
        //             pixel_count: response.pixel_count,
        //             sale_bump_count_last30d: response.sale_bump_count_last30d,
        //             sale_smartsale_count_last30d: response.sale_smartsale_count_last30d,
        //             sale_affiliate_count_last30d: response.sale_affiliate_count_last30d,
        //             club_count: response.club_count,
        //             sale_imcosseler_amount_last30d: response.sale_imcosseler_amount_last30d
        //         })
        //     })      
        //     .catch(err => console.error(err))
        //     .finally()            
        // }
        
    },
    userClub: (state, resp) => {
        state.userClub = resp;
        Cookies.set('club_integration', JSON.stringify(resp), {
            expires: 365
        });
    },
    verifyEmail: (state, resp) => {
        state.verifyEmail = resp;
        Cookies.set('verify_email', JSON.stringify(resp), {
            expires: 365
        });
    },
    userLogged: (state, resp) => {
        state.userLogged = resp;
        localStorage.userLogged = JSON.stringify(resp)
    },
    setPanel: (state, resp) => {
        state.setPanel = resp;
        Cookies.set('setPanel', resp, {
            expires: 7
        });
    },
    setPanels: (state, resp) => {
        state.setPanels = resp;
    },
    resetPanel: (state, resp) => {
        state.setPanel = null;
        Cookies.remove('setPanel');
    },
    setPermissionsPanel: (state,resp) => {
        state.permissionsPanel = resp;
    },
    adminUserSuccess: (state, resp) => {
        state.user = resp;
    },
    userLogout: (state) => {
        const locationadm = localStorage.locationadm
        state.user = null;
        state.userClub = null;
        state.userLogged = null;
        state.verifyEmail = null
        state.setPanel = null
        state.access_token = '';
        Cookies.remove('access_token');
        Cookies.remove("setPanelName");
        Cookies.remove("setPanelEmail");
        Cookies.remove("setPanelPermission");
        Cookies.remove('setPanel');
        Cookies.remove('club_integration');
        Cookies.remove('user');
        Cookies.remove('verify_email');
        sessionStorage.clear();
        localStorage.clear();
        localStorage.locationadm = locationadm;
        let url = window.location.protocol + "//" + window.location.host;
        if (window.location.search.includes('cashback=')) {
            url += window.location.search;
        }
        window.location.href = url;
    },
    setHash(state, value) {
        state.hash = value
    },
    setIndication(state, value) {
      state.indication = value
    },
    setAccountType(state, value) {
      state.account_type = value
    },
    setLastMonthStatistics(state,resp) {
        state.statistics = resp
    },
    setCustomEmail(state,value) {
        if(value.length){
            value.forEach((item) => {
                if (item.key == "CHARGEBACK_EMAIL" && item.value !== null) {
                    state.customEmail = item.value
                }
              })
        }else{
            state.customEmail = null;
        }
    },
    setSubUser(state, value){
        state.subUser = value;
    },
    setSubUserLoading(state, value){
        state.subUserLoading = value;
    },
    setintegrationGreennSales(state, value){
        state.integrationGreennSales = value;
    }
}