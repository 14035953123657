import Cookies from 'js-cookie';

import actions from '@/store/modules/user/actions'
import getters from '@/store/modules/user/getters';
import mutations from '@/store/modules/user/mutations'


const state = {
    user: (localStorage.user) ? JSON.parse(localStorage.user) : {},
    verifyEmail: (String(Cookies.get('verify_email')) == "true"),
    userClub: (String(Cookies.get('club_integration')) == "true"),
    setPanel: Cookies.get('setPanel') ? Cookies.get('setPanel') : null,
    setPanels:[],
    userLogged: (localStorage.userLogged) ? JSON.parse(localStorage.userLogged) : {},
    hash: null,
    indication: false,
    account_type: null,
    statistics: null,
    customEmail:null,
    subUser: Cookies.get('setSubUser') ? JSON.parse(Cookies.get('setSubUser')) : {},
    subUserLoading:false,
    permissionsPanel:[],
    integrationGreennSales:{}
}


export {
    state,
    getters,
    actions,
    mutations,
}